<template>
    <el-main>
        <el-form label-width="140px">
            <el-tabs v-model="tab_active">
                <el-tab-pane name="subscription" label="时价订阅">
                    <el-form-item label="订阅价格：" required>
                        <div class="tips">此处设置为会员订阅时价，各个不同分类的基础价格，不包括期限和会员折扣</div>
                        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" border
                            :span-method="arraySpanMethod">
                            <template slot="empty">
                                <No />
                            </template>
                            <el-table-column prop="pname" label="时价分类（二级类目）" align="center" width="280"></el-table-column>
                            <el-table-column prop="name" label="时价分类（三级类目）" align="center" width="280"></el-table-column>
                            <el-table-column prop="price" align="center">
                                <template slot="header">
                                    <span style="color: #f56c6c">*</span>
                                    权限价格
                                </template>
                                <template slot-scope="scope">
                                    <el-input style="margin-right: 10px; width: 260px"
                                        v-model="subscription_list[scope.row.pindex].data[scope.row.index].price"></el-input>
                                    元/日
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane name="discount" label="期限折扣">
                    <el-form-item label="订阅期限折扣：" required>
                        <div class="tips">此处仅设置金额，积分兑换根据积分和人民币兑换比例自动前端展示</div>
                        <div class="row" v-for="(item, index) in subscription_discount" :key="index">
                            <div class="row_left">{{ item.price ? '试用' : '' }}</div>
                            <el-input class="row_input" v-model="item.day"></el-input>
                            <div class="row_center">天，{{ item.price ? '固定价格' : '折扣' }}</div>
                            <el-input class="row_input" v-model="item.price" v-if="item.price"></el-input>
                            <el-input class="row_input" v-model="item.discount" v-else></el-input>
                            {{ item.price ? '元' : '折' }}
                            <el-link icon="el-icon-circle-close" :underline="false" v-if="index > 0"
                                @click="deleteDiscount(index)"></el-link>
                            <div class="tipss" v-if="index == 1">例如：10折为100%不打折，9折为90%，0.12折为1.2%</div>
                        </div>
                        <div class="row">
                            <div class="row_left"></div>
                            <el-button type="text" icon="el-icon-plus" @click="addDiscount">添加</el-button>
                        </div>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane name="explains" label="时价数据导入">
                    <el-button size="small" type="primary" @click="showImport = !0"
                        style="margin-bottom: 15px">表格导入</el-button>
                    <div class="tips">注: 导入要较长时间，不用在此页等待，如上传失败，代表数据过多，请删减数据重新上传如上传成功，则需等待，数据同步后会在时价列表显示。</div>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <Preservation @preservation="save"></Preservation>
        <el-dialog title="导入时价" :visible.sync="showImport" width="550px">
            <el-link type="primary" :href="templatePath">下载导入模板</el-link>
            <el-input class="upload" v-model="filePath">
                <el-button slot="append">
                    上传文件
                    <input type="file" ref="inputer" @change="getFile" />
                </el-button>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showImport = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmImport">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import config from '@/util/config';
import { inspectPrice } from "@/util/verification"
export default {
    components: {
        Preservation,
    },
    data () {
        return {
            tab_active: 'subscription',
            subscription_list: [],
            subscription_discount: [],
            explains: '',
            // 导入
            showImport: !1,
            formData: '',
            templatePath: config.baseurl + '/' + 'Upload/import/current_prices/时价导入模板.xlsx',
            filePath: '',
        };
    },
    created () {
        this.getInfo();

        this.$axios.post(this.$api.gateway.currentprice.getTemplate).then(async res => {
            console.log(res.result);
            //     this.templatePath = config.baseurl + '/' + res.result;
        })
    },
    computed: {
        list () {
            let arr = [];
            let subscription_list = this.subscription_list.concat();
            subscription_list.forEach((item, index) => {
                let obj = Object.assign({}, item);
                obj.data.forEach((v, i) => {
                    let _obj = Object.assign({}, v);
                    _obj.pid = obj.id;
                    _obj.pname = obj.name;
                    _obj.num = obj.data.length;
                    _obj.index = i;
                    _obj.pindex = index;
                    _obj.price = obj.price || '';
                    arr.push(_obj);
                });
            });
            return arr;
        },
    },
    methods: {
        getInfo () {
            this.$axios.post(this.$api.gateway.currentprice.setInfo).then(async res => {
                if (res.code == 0) {
                    let typeList = await this.getType();
                    this.subscription_list = res.result.subscription_prices ? res.result.subscription_prices.concat() : typeList;
                    this.subscription_discount = JSON.parse(res.result.subscription_discount);
                    this.explains = res.result.explains;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getType () {
            return new Promise((resolve, reject) => {
                this.$axios.post(this.$api.gateway.currentprice.classify).then(res => {
                    if (res.code == 0) {
                        resolve(res.result);
                    } else {
                        this.$message.error(res.msg);
                        reject(res);
                    }
                });
            });
        },
        arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 0) {
                if (row.index == 0) {
                    return [row.num, 1];
                } else {
                    return [0, 0];
                }
            }
        },
        save () {
            let url = '';
            let data = {};
            if (this.tab_active == 'subscription') {
                for (let v of this.subscription_list) {
                    if (v.data.some(item => item.price <= 0 && item.price)) {
                        return this.$message.error('价格必须大于0');
                    }
                    if (v.data.some(item => !item.price)) {
                        return this.$message.error('价格填写完整');
                    }
                    if (v.data.some(item => !inspectPrice(item.price))) {
                        return this.$message.error('请输入正确价格格式');
                    }
                }
                url = this.$api.gateway.currentprice.editSubscriptionPrice;
                data.subscription_price = this.subscription_list;
            } else if (this.tab_active == 'discount') {
                for (let v of this.subscription_discount) {
                    if (v.price && !inspectPrice(v.price)) {
                        return this.$message.error('请输入正确价格格式');
                    }
                }
                url = this.$api.gateway.currentprice.editSubscriptionDiscount;
                data.subscription_discount = this.subscription_discount;
            } else {
                url = this.$api.gateway.currentprice.editExplains;
                data.explains = this.explains;
            }

            this.$axios.post(url, data).then(res => {
                if (res.code == 0) {
                    this.$message.success('保存成功');
                    this.getInfo();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        addDiscount () {
            this.subscription_discount.push({
                day: '',
                discount: '',
            });
        },
        deleteDiscount (index) {
            this.subscription_discount.splice(index, 1);
        },
        getFile () {
            let files = this.$refs.inputer.files[0];
            let formData = new FormData();
            formData.append('file', files);
            this.filePath = files.name;
            this.formData = formData;
        },
        // 导入文件
        comfirmImport () {
            if (!this.formData) return this.$message.warning('请先上传文件');
            this.$axios
                .uploadFile(this.$api.gateway.currentprice.import, this.formData)
                .then(res => {
                    if (res.code == 0) {
                        this.showImport = !1;
                        this.$message.success('时价导入成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    min-height: 100%;

    .upload {
        margin-top: 20px;

        .el-button {
            position: relative;

            input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
            }
        }
    }

    .tips {
        color: #888888;
        font-size: 14px;
        margin-bottom: 30px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;

        &+.row {
            margin-top: 15px;
        }

        .row_left {
            width: 40px;
        }

        .row_input {
            width: 260px;
            margin-right: 10px;
        }

        .row_center {
            width: 95px;
        }

        .el-link {
            margin-left: 10px;
            font-size: 20px;
        }

        .tipss {
            color: #888888;
            font-size: 14px;
            margin-left: 20px;
        }
    }
}
</style>
